import { stringify } from 'query-string';

import {
  PAGINATION_RAWMATERIAL_MAX,
  PAGINATION_CONSUMERDBS_MAX,
  PAGINATION_FOLDERS_MAX,
  PAGINATION_REPORTS_MAX,
  PAGINATION_USER_MAX,
  PAGINATION_GROUPS_MAX,
} from '../data/constants';
import {
  ASSOCIATED_INGREDIENT_TYPE,
  INGREDIENT_TYPE,
} from '../pages/FormulatorAdmin/FormulatorRawMaterialsView/consts';
import { segmentsTrack } from '../utils/trackEvents';
import { SORTING_TYPES } from '../pages/FormulatorAdmin/FormulatorMyFormulas/const';

import axiosInstance from './axiosInstance';
import { catchFunction, getConfig } from './utils';
import {
  REQUEST_DOCUMENTS_SUCCESS,
  SET_RM_EDITMODE,
  CLEAR_PARAMS,
  CLEAR_FORMULATOR_PRODUCT,
  CLEAR_FORMULATOR_FORMULA,
  CLEAR_PRODUCT_DBS,
  CLEAR_PRODUCT_SIZES,
  CLEAR_RESTRICTIONS,
  CLEARE_PRODUCT,
  CREATE_PRODUCT_DBS,
  CREATE_PRODUCT_NOTE,
  CREATE_PRODUCT_SIZES,
  CREATE_PHASE,
  DELETE_PRODUCT_SIZES,
  DELETE_SHARED_FORMULA_PRODUCTS_BULK,
  SHARE_FORMULA_PRODUCTS_BULK_WITH_PARTNERS,
  CREATE_PRODUCTS,
  CLOSE_ERROR,
  COPY_INGREDIENT,
  CREATE_COMPOUND_INGREDIENT,
  CREATE_NEW_FOLDER,
  DUPLICATE_COMPOUND_INGREDIENT,
  DUPLICATE_PRODUCT,
  DELETE_RM,
  DELETE_COMPANY_USER,
  DELETE_FOLDERS_BULK,
  DELETE_RM_FILE_SUCCESS,
  GET_COMPOUND_INGREDIENT,
  GET_APPLICATIONS,
  GET_CERTIFICATIONS,
  GET_COMPANIES,
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_USERS,
  GET_COMPOUND_INGREDIENT_ALLERGEN,
  GET_COMPOUND_INGREDIENT_ASSOCIATED_INCI,
  GET_COMPOUND_INGREDIENT_IMPUTITY,
  GET_COMPOUND_INGREDIENT_PARAM,
  GET_COUNTRIES,
  GET_BLACK_LIST,
  GET_BLACK_LIST_ITEMS,
  DOWNLOAD_REPORT,
  FORBIDDEN_BLACKLIST,
  GENERATE_PRODUCTS_REPORTS,
  GET_FORMULATOR_PRODUCT,
  GET_COMPOUND_INGREDIENTS,
  GET_CUSTOM_TAGS,
  GET_FILE_TAGS,
  GET_FILE_TYPES,
  GET_FOLDERS,
  GET_FUNCTIONS_LIST,
  GET_INGREDIENTS_FOR_LIBRARY,
  GET_INGREDIENT,
  GET_PHASES,
  GET_PRODUCT_BENEFITS,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_DBS,
  GET_PRODUCT_NOTES,
  GET_INGREDIENTS,
  GET_MANUFACTURERS,
  GET_ORIGINS,
  GET_PARTNERSHIPS,
  GET_PARTNERSHIPS_SHARED_ITEMS,
  GET_PRODUCT_RESTRICTION_TAGS,
  GET_PRODUCT_SIZES,
  GET_PRODUCT_SUB_CATEGORIES,
  GET_PRODUCT_TYPES,
  GET_SHARED_PRODUCT,
  GET_PRODUCTS,
  GET_REGULATORY,
  GET_TAGS,
  GET_SUPPLIERS,
  GET_TOXINS,
  GET_TOXIN_CATEGORIES,
  GET_REPORTS,
  GET_SHARED_PRODUCT_BRANDS,
  REFRESH_SHARED_PRODUCT,
  GET_RESTRICTION_ANALYZE,
  GET_SOURCES,
  GET_USER_COMPANY_CURRENCY,
  GET_USER_NOTIFICATIONS,
  GET_USER_PROFILE,
  GLOBAL_ERROR,
  REMOVE_REPORT,
  LOG_REGULATORY_LINK_SUCCESS,
  MARK_ALL_AS_READ_SUCCESS,
  REMOVE_PARNTERSHIP,
  REMOVE_BLACKLIST_ITEM,
  REMOVE_COMPOUND_INGREDIENT,
  REQUEST_REVIEW_INGREDIENT,
  REQUEST_REVIEW_MANUFACTURERS,
  REQUEST_REVIEW_SUPPLIER,
  REQUEST_REVIEW_RM,
  UPDATE_COMPOUND_INGREDIENT,
  SET_FORMULA_EDITMODE,
  SET_SHOW_EDIT_CONFIRM_MODAL,
  SET_UPLOADED_DOCS,
  UPDATE_BLACK_LIST_ITEM,
  UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD,
  UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST,
  UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD,
  UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST,
  UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD,
  UPDATE_COMPOUND_INGREDIENT_PARAM_LIST,
  UPDATE_FOLDER_BULK,
  UPDATE_INGREDIENT,
  UPDATE_INGREDIENT_TAG,
  UPDATE_PERCENTAGE,
  REQUEST_SYNC_CENTRIC_SUCCESS,
  UPDATE_PRODUCT_DBS,
  UPDATE_PRODUCT_INGREDIENTS,
  UPDATE_PRODUCT_NOTE,
  UPDATE_PRODUCT_SIZES,
  UPDATE_USER_COMPANY,
  UPDATE_USER_PROFILE,
  UPLOAD_FILE_COMPOUND,
  UPDATE_PRODUCTS,
  GET_TABLE_LAYOUT,
  GET_REGIONS,
  SET_PREVIOUS_LOCATION,
  LOCK_COMPOUND_INGREDIENT,
  GET_FORMULATOR_FORMULA_PARAMS,
  GET_FORMULATOR_FORMULA_COMPOUND_PARAMS,
  GET_FORMULA_COMPOUND_PARAM_INGREDIENTS,
  UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT,
  UPDATE_FORMULA_INGREDIENT,
  GET_FORMULA_FILES,
  DELETE_FORMULA_FILE,
  UPDATE_FORMULA_FILE,
  UPLOAD_FORMULA_FILE,
  UPDATE_FORMULA_INGREDIENT_COMPOUND,
  UPDATE_REQUEST_REVIEW_INGREDIENT,
  ADD_FORMULA_INGREDIENT_COMPOUND,
  DELETE_FORMULA_INGREDIENT_COMPOUND,
  UPDATE_PRODUCT_GROUPS,
  GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION,
  GET_FORMULATOR_FORMULA_TOTAL_COST,
  UPDATE_FORMULATOR_FORMULA_NF_PARAMS,
  GET_COMPOUND_INGREDIENT_INCIDENTAL,
  SET_SHOW_MAINTENANCE_MESSAGE,
  UPDATE_PRODUCTS_STATUS,
  UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST,
  GET_USER_PERMISSIONS,
  GET_COMPANY_PERMISSIONS,
  UPDATE_COMPOUND_INGREDIENT_STATUS,
  UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD,
  GET_ALL_PERMISSIONS,
  GET_EDITED_USER_PERMISSIONS,
  GET_IN_MARKET_BRANDS,
  GET_EDITED_USER,
  GET_ALL_PERMISSIONS_STARTED,
  GET_COMPANY_GRAND_CATEGORIES,
  GET_COMPANY_TYPES,
  GET_COMPANY_BRANDS,
  GET_SCREENING_NOTIFICATIONS,
  RESORT_FORMULA_PARAMS,
  FORMULATOR_FORMULA_RESORT,
  LOAD_COMPANY_PROFILE_USERS,
  TRACK_DOWNLOAD_SPL,
  GET_MAX_PRICE,
  GET_FORMULATOR_FORMULA_TOTAL_NATURALITY,
  GET_COMPANY_FORMULA_BRANDS,
  GET_COMPANY_FORMULA_STATUSES,
  UPDATE_CHANGED_RM_IN_FORMULA,
  GET_FORMULATOR_FORMULA_TOTAL_BIODEGRADABILITY,
  GET_SMART_FORMULAS,
  GET_FORMULATOR_FORMULA_SMART_FORMULAS,
  GET_FORMULA_SMART_FORMULA_INGREDIENTS,
  UPDATE_FORMULA_SMART_INGREDIENT,
  BREAK_SMART_FORMULA,
  GET_SMART_PHASES,
  UNLOCK_FORMULATOR_PRODUCT,
  GET_FORMULATOR_DRAFT_ID,
  REPLACE_SMART_FORMULA,
  GET_FORMULATOR_FORMULA_TOTAL_NATURAL_ORIGIN,
  GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_CONTENT,
  GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_ORIGIN,
} from './types';

export function setRmEditmode(value) {
  return {
    type: SET_RM_EDITMODE,
    value,
  };
}

export function setFormulaEditmode(value) {
  return {
    type: SET_FORMULA_EDITMODE,
    value,
  };
}

export function setShowEditConfirmModal(value) {
  return {
    type: SET_SHOW_EDIT_CONFIRM_MODAL,
    value,
  };
}
export function getFolders(params) {
  const {
    search,
    product_search,
    folder_ids = [],
    categories = [],
    subcategories = [],
    product_types = [],
    user_ids = [],
    brands = [],
    formats = [],
    status_ids = [],
    modified_from,
    modified_to,
    created_from,
    created_to,
    in_market,
    is_shared,
    toxin_group_ids = [],
    black_list_ids = [],
    smart_ids = [],
    offset = 0,
    product_count,
    is_light_mode,
    delete_empty,
    folder_group_id = [],
    raw_material_id,
  } = params

  const rowMaterialId = raw_material_id ? `&raw_material_id=${raw_material_id}` : '';

  const isLightMode = is_light_mode ? `&is_light_mode=${is_light_mode}` : '';

  const filterSearch = product_search ? `&search=${encodeURIComponent(product_search || search)}` : '';
  const filterFolders = folder_ids?.length ? `&folder_id=${folder_ids.join('&folder_id=')}` : '';
  const filterCategories = categories?.length ? `&category_id=${categories.join('&category_id=')}` : '';
  const filterSubCategories = subcategories?.length
    ? `&subcategory_id=${subcategories.join('&subcategory_id=')}` : '';
  const filterTypes = product_types?.length
    ? `&product_type_id=${product_types.join('&product_type_id=')}` : '';
  const filterToxinGroup = toxin_group_ids?.length
    ? `&toxin_group_ids=${toxin_group_ids.join('&toxin_group_ids=')}` : '';
  const filterBlackList = black_list_ids?.length
    ? `&black_list_ids=${black_list_ids.join('&black_list_ids=')}` : '';
  const filterUserIds = user_ids?.length ? `&user_ids=${user_ids.join('&user_ids=')}` : '';
  const filterBrands = brands?.length ? `&brands=${brands.join('&brands=')}` : '';
  const filterFormats = formats?.length ? `&formats=${formats.join('&formats=')}` : '';
  const filterStatus = status_ids?.length ? `&status_ids=${status_ids.join('&status_ids=')}` : '';
  const filterSmartFormulas = smart_ids?.length ? `&smart_ids=${smart_ids.join('&smart_ids=')}` : '';
  const filterModifiedFrom = modified_from ? `&modified_from=${modified_from}` : '';
  const filterModifiedTo = modified_to ? `&modified_to=${modified_to}` : '';
  const filterCreatedFrom = created_from ? `&created_from=${created_from}` : '';
  const filterCreatedTo = created_to ? `&created_to=${created_to}` : '';
  const filterInMarket = in_market !== undefined ? `&in_market=${in_market}` : '';
  const filterIsShared = is_shared !== undefined ? `&is_shared=${is_shared}` : '';
  const productCountParamIncludes = typeof product_count === 'boolean';
  const productCountParam = productCountParamIncludes ? `&product_count=${product_count}` : '';
  const deleteEmptyParam = delete_empty ? '&delete_empty=true' : '';
  const groupIdParam = folder_group_id?.length ? `&folder_group_id=${folder_group_id}` : '';

  const filters = `${filterSearch}${filterFolders}${filterCategories}${filterSubCategories}${filterTypes}${filterToxinGroup}${filterBlackList}${productCountParam}${deleteEmptyParam}${groupIdParam}${rowMaterialId}${filterUserIds}${filterBrands}${filterSmartFormulas}${filterFormats}${filterStatus}${filterModifiedFrom}${filterModifiedTo}${filterCreatedFrom}${filterCreatedTo}${filterInMarket}${filterIsShared}`;

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/folders?offset=${offset}&limit=${PAGINATION_FOLDERS_MAX}${filters}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FOLDERS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFolders(params),
        });
      });
  };
}

export function getFoldersList() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/folders?product_count=false`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFoldersList(),
        });
      });
  };
}

export function getFormulas(params) {
  const {
    filterFolder,
    offset,
    filters,
    sortApplied,
    limit = 100,
    raw_material_id,
  } = params;

  const rawMaterialId = raw_material_id ? `&raw_material_id=${raw_material_id}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/formulas?limit=${limit}&offset=${offset}${filters}&sort=${sortApplied}${filterFolder ? `&folder_id=${filterFolder}` : ''}${rawMaterialId}`,
        getConfig()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulas(params),
        });
      });
  };
}

export function getAllFormulas(params) {
  const isSearch = params.search
    ? `&search=${encodeURIComponent(params.search)}`
    : '';
  const rawMaterialId = params.raw_material_id ? `&raw_material_id=${params.raw_material_id}` : '';

  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/formulator/formulas?limit=${PAGINATION_FOLDERS_MAX}&offset=${params.offset || 0}${params.filterFolder ? `&folder_id=${params.filterFolder}` : ''}&sort=${params.sortApplied || SORTING_TYPES.NAME_ASC}${params.filters || ''}${isSearch}${rawMaterialId}`,
            getConfig()
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getAllFormulas(params),
          });
        });
  };
}

export function getGroups(params) {
  const isSearch = params.search
    ? `&search=${encodeURIComponent(params.search)}`
    : '';
  const isOnlyGroups = params.only_groups ? '&only_groups=true' : '';

  const rawMaterialId = params.raw_material_id
    ? `&raw_material_id=${params.raw_material_id}`
    : '';

  return (dispatch) => {
    return axiosInstance
        .get(
            `/rest_api/formulator/groups?limit=${PAGINATION_GROUPS_MAX}&offset=${params.offset || 0}${params.filterFolder ? `&folder_id=${params.filterFolder}` : ''}${params.filters || ''}${isSearch}${isOnlyGroups}${rawMaterialId}`,
            getConfig()
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getGroups(params),
          });
        });
  };
}

export function getFoldersWithPagination(
  params
) {

  const searchValue = params.foldersSearch ? `&search=${encodeURIComponent(params.foldersSearch)}` : '';

  return (dispatch) => {
    return axiosInstance
        .get(`/rest_api/formulator/folders?offset=${params.currentOffsetFolders}${searchValue}`, getConfig())
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: GET_FOLDERS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getFoldersWithPagination(params),
          });
        });
  };
}

export function duplicateProduct(product_id) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${product_id}/duplicate`,
        {},
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DUPLICATE_PRODUCT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: duplicateProduct(product_id),
        });
      });
  };
}

export function duplicateFormula(product_id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${product_id}/duplicate`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DUPLICATE_PRODUCT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: duplicateFormula(product_id, data),
        });
      });
  };
}

export function getDuplicateFormulaName(product_id) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/formulas/${product_id}/duplicate`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DUPLICATE_PRODUCT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getDuplicateFormulaName(product_id),
        });
      });
  };
}

export function getProductCategories() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/categories`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_CATEGORIES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductCategories(),
        });
      });
  };
}

export function getProductSubCategories() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/subcategories`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_SUB_CATEGORIES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductSubCategories(),
        });
      });
  };
}

export function getProductTypes() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/types`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_TYPES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductTypes(),
        });
      });
  };
}

export function getResponsiblePartyTypes() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/responsible-type`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getResponsiblePartyTypes(),
        });
      });
  };
}

export function getFacilities(searchedFacility) {
  const searchValue = searchedFacility ? `?search=${encodeURIComponent(searchedFacility)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/facilities${searchValue}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFacilities(searchedFacility),
        });
      });
  };
}

export function postFacility(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/companies/company/facilities`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: postFacility(data),
        });

        return error?.response;
      });
  };
}

export function getProductRestrictionTags() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/products/restriction_tags`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_RESTRICTION_TAGS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductRestrictionTags(),
        });
      });
  };
}

export function getFormulasRestrictionTags() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/restriction-tags`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_RESTRICTION_TAGS,
          data: response.data,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulasRestrictionTags(),
        });
      });
  };
}

export function getApplications(offset = 0, search = '') {
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/applications?offset=${offset}${searchValue}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_APPLICATIONS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getApplications((offset = 0), (search = '')),
        });
      });
  };
}
export function getProducts(params) {
  const {
    offset = 0,
    search,
    sort,
    brands,
    product_types,
    is_light_mode,
    safety,
  } = params
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const sortQuery = sort ? `&sort=${sort}` : '';
  const brandNames = brands?.length
    ? `&brand_name=${brands.map((b) => encodeURIComponent(b)).join('&brand_name=')}`
    : '';
  const filterTypes = product_types?.length
    ? `&product_type_id=${product_types.join('&product_type_id=')}`
    : '';
  const isLightMode = is_light_mode ? `&is_light_mode=${is_light_mode}` : '';
  const filterSafeties = safety?.length
    ? `&safety=${safety.join('&safety=')}`
    : '';
  const limit = `&limit=${PAGINATION_CONSUMERDBS_MAX}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products?offset=${offset}${limit}${searchQuery}${sortQuery}${isLightMode}${brandNames}${filterTypes}${filterSafeties}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProducts(params),
        });
      });
  };
}
export function getPublishedProducts(params) {
  const {
    offset = 0,
    search,
    brand_ids,
    product_types,
    safety,
  } = params
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const brandsIds = brand_ids?.length
    ? `&brand_id=${brand_ids.join('&brand_id=')}`
    : '';
  const filterTypes = product_types?.length
    ? `&product_type_id=${product_types.join('&product_type_id=')}`
    : '';
  const filterSafeties = safety?.length
    ? `&safety=${safety.join('&safety=')}`
    : '';
  const limit = `&limit=${PAGINATION_CONSUMERDBS_MAX}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products/shared?offset=${offset}${limit}${searchQuery}${brandsIds}${filterTypes}${filterSafeties}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPublishedProducts(params),
        });
      });
  };
}
export function getMyPublishedProducts(params) {
  const { offset = 0, search, brand_ids, product_types, safety } = params;
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const brandsIds = brand_ids?.length
    ? `&brand_id=${brand_ids.join('&brand_id=')}`
    : '';
  const filterTypes = product_types?.length
    ? `&product_type_id=${product_types.join('&product_type_id=')}`
    : '';
  const filterSafeties = safety?.length
    ? `&safety=${safety.join('&safety=')}`
    : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products/manage?offset=${offset}${searchQuery}${brandsIds}${filterTypes}${filterSafeties}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getMyPublishedProducts(params),
        });
      });
  };
}

export function getPublishedSingleProduct(id, isSharedByMe = false) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products/${isSharedByMe ? 'manage' : 'shared'}/${id}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPublishedSingleProduct(id, isSharedByMe),
        });
      });
  };
}

export function createSharedECommerces(id, data, isSharedByMe) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/${
          isSharedByMe ? 'manage' : 'shared'
        }/${id}/ecommerces`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createSharedECommerces(id, data, isSharedByMe),
        });

        return error;
      });
  };
}
export function updateSharedECommerces(params) {
  const { id, ecommerce_id, data, isSharedByMe = false } = params;

  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/products/${
          isSharedByMe ? 'manage' : 'shared'
        }/${id}/ecommerces/${ecommerce_id}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateSharedECommerces(params),
        });

        return error;
      });
  };
}
export function deleteSharedECommerces(params) {
  const { id, ecommerce_id, isSharedByMe = false} = params

  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${
          isSharedByMe ? 'manage' : 'shared'
        }/${id}/ecommerces/${ecommerce_id}`,
        getConfig(),
      )
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteSharedECommerces(params),
        });
      });
  };
}

export function activateProductDBS(id) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/manage/${id}/activate`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: activateProductDBS(id),
        });
      });
  };
}

export function activateSharedProduct(id, ecommerce_id) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/shared/${id}/ecommerces/${ecommerce_id}/activate`,
        null,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: activateSharedProduct(id, ecommerce_id),
        });
      });
  };
}

export function deactivateSharedProduct(id, ecommerce_id) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/shared/${id}/ecommerces/${ecommerce_id}/deactivate`,
        null,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deactivateSharedProduct(id, ecommerce_id),
        });
      });
  };
}

export function deleteSharedProduct(id, isSharedByMe) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${isSharedByMe ? 'manage' : 'shared'}/${id}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteSharedProduct(id, isSharedByMe),
        });
      });
  };
}

export function getApiDestinations() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/api_destinations`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getApiDestinations(),
        });
      });
  };
}
export function consumerDBPublish(params) {
  const reqData = {
    product_ids: params.productIds,
    partnership_ids: params.destinations,
    gfp_site: !!params.isGoodFaceSelected,
    destination_ids: params.myDestinationSelected,
  };

  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/publish`, reqData, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: consumerDBPublish(params),
        });
      });
  };
}
export function deleteProductDBS(data) {
  const reqData = {
    products_ids: data,
  };

  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/products`, getConfig(reqData))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductDBS(data),
        });
      });
  };
}

export function updateProductDBS(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/products/${productId}`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_DBS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductDBS(productId, data),
        });
      });
  };
}

export function deleteProductDBSParam(productId, paramId) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/products/${productId}/params/${paramId}`, getConfig())
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductDBSParam(productId, paramId),
        });
      });
  };
}

export function createProductDBS(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCT_DBS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createProductDBS(data),
        });
      });
  };
}

export function logSPLButton(productId) {
  return (dispatch) => {
    return axiosInstance
      .post(`rest_api/products/${productId}/spl_button_log`, {}, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: logSPLButton(productId),
        });
      });
  };
}

export function getProductData(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/${productId}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_DBS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductData(productId),
        });
      });
  };
}

export function getSPLProductData(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/${productId}/spl`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_DBS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSPLProductData(productId),
        });
      });
  };
}

export function generateSPLProductData(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/${productId}/spl`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: generateSPLProductData(productId, data),
        });
      });
  };
}
export function segmentDownloadSpl(id) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/reports/segment-download-spl/${id}`, {report_id: id}, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: segmentDownloadSpl(id),
        });
      });
  };
}
export function setProductCoverImage(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/${productId}/images`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: setProductCoverImage(productId, data),
        });
      });
  };
}

export function deleteProductCoverImage(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/products/${productId}/images`, getConfig(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductCoverImage(productId, data),
        });
      });
  };
}

export function getProductIngredientParams(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/${productId}/params`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        // dispatch({
        //   type: GET_PRODUCT_DBS,
        //   data: response
        // })
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductIngredientParams(productId),
        });
      });
  };
}

export function syncProductIngredientParams(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/products/${productId}/params`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: syncProductIngredientParams(productId, data),
        });
      });
  };
}

export function createProductIngredientParams(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/${productId}/params`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        // dispatch({
        //   type: GET_PRODUCT_DBS,
        //   data: response
        // })
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createProductIngredientParams(productId, data),
        });
      });
  };
}

export function updateProductIngredientParams(productId, paramId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/products/${productId}/params/${paramId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        // dispatch({
        //   type: GET_PRODUCT_DBS,
        //   data: response
        // })
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductIngredientParams(
            productId,
            paramId,
            data,
          ),
        });
      });
  };
}

export function getProductIngredientLabels(productId) {
  return (dispatch) => axiosInstance
    .get(`/rest_api/products/${productId}/labels`, getConfig())
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: getProductIngredientParams(productId),
      });
    });
}

export function deleteProductIngredientLabel(productId, labelId) {
  return (dispatch) => axiosInstance
    .delete(`/rest_api/products/${productId}/labels/${labelId}`, getConfig(),)
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: deleteProductIngredientLabel(productId, labelId),
      });
    });
}

export function createProductIngredientLabel(productId, data) {
  return (dispatch) => axiosInstance
    .post(`/rest_api/products/${productId}/labels`, data, getConfig())
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: createProductIngredientLabel(productId, data),
      });
    });
}

export function createProductIngredientLabels(productId, data) {
  return (dispatch) => axiosInstance
    .post(`/rest_api/products/${productId}/labels/many`, data, getConfig())
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: createProductIngredientLabel(productId, data),
      });
    });
}

export function copyProductIngredientFormulasToLabels(productId) {
  return (dispatch) => axiosInstance
    .post(`/rest_api/products/${productId}/labels/copy`, {}, getConfig())
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: copyProductIngredientFormulasToLabels(productId),
      });
    });

}

export function reorderProductIngredientsLabel(productId, ids) {
  return (dispatch) => axiosInstance
    .post(`/rest_api/products/${productId}/labels/resort`, { ids }, getConfig())
    .then((response) =>  response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: createProductIngredientLabel(productId, ids),
      });
    });
}

export function updateProductIngredientsLabel(productId, labelId, data) {
  return (dispatch) => axiosInstance
    .patch(`/rest_api/products/${productId}/labels/${labelId}`, data, getConfig())
    .then((response) => response.data)
    .catch((error) => {
      catchFunction({
        error,
        dispatch,
        refreshFunction: updateProductIngredientsLabel(productId, labelId, data),
      });
    });
}

export function uploadFileProductDBS(productId, data, paramId) {
  const reqUrl = paramId
    ? `/rest_api/products/${productId}/params/${paramId}/files`
    : `/rest_api/products/${productId}/files`;

  return (dispatch) => {
    return axiosInstance
      .post(reqUrl, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: uploadFileProductDBS(productId, data, paramId),
        });
      });
  };
}

export function deleteProductDBSFile(ingredientId, fileId, paramId) {
  const reqUrl = `/rest_api/products/${ingredientId}/files/${fileId}`;
  const data = paramId ? { param_id: paramId } : undefined;

  return (dispatch) => {
    return axiosInstance
      .delete(reqUrl, getConfig(data))
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_RM_FILE_SUCCESS,
          data: response,
        });

        return DELETE_RM_FILE_SUCCESS;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductDBSFile(ingredientId, fileId, paramId),
        });
      });
  };
}
export function updateFileProductDBS(params) {
  const { productId, data, fileId, paramId } = params
  const reqUrl = paramId
    ? `/rest_api/products/${productId}/params/${paramId}/files/${fileId}`
    : `/rest_api/products/${productId}/files/${fileId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFileProductDBS(params),
        });
      });
  };
}

export function getProductSizes(id) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/${id}/sizes`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_SIZES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductSizes(id),
        });
      });
  };
}

export function updateProductSizes(id, product_size_id, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/products/${id}/sizes/${product_size_id}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_SIZES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductSizes(id, product_size_id, data),
        });

        return error;
      });
  };
}

export function deleteProductSizes(id, product_size_id) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/products/${id}/sizes/${product_size_id}`, getConfig())
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: DELETE_PRODUCT_SIZES,
            data: { id: product_size_id },
          });
        }
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductSizes(id, product_size_id),
        });
      });
  };
}

export function getProductSizesShades(id, product_size_id) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products/${id}/sizes/${product_size_id}/shades`,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductSizesShades(id, product_size_id),
        });
      });
  };
}

export function addProductSizesShades(id, product_size_id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/${id}/sizes/${product_size_id}/shades`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: addProductSizesShades(id, product_size_id, data),
        });
      });
  };
}
export function updateProductSizesShades(params) {
  const { id, product_size_id, product_shade_id, data } = params

  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/products/${id}/sizes/${product_size_id}/shades/${product_shade_id}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductSizesShades(params),
        });
      });
  };
}

export function deleteProductSizesShade(id, product_size_id, product_shade_id) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${id}/sizes/${product_size_id}/shades/${product_shade_id}`,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductSizesShade(
            id,
            product_size_id,
            product_shade_id,
          ),
        });
      });
  };
}

export function deleteBulkProductSizeShades(id, product_size_id, data) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${id}/sizes/${product_size_id}/shades/bulk`,
        getConfig(data),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteBulkProductSizeShades(
            id,
            product_size_id,
            data,
          ),
        });
      });
  };
}

export function getProductVariationsShades(id) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/products/${id}/shades`,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductVariationsShades(id),
        });
      });
  };
}

export function patchProductVariationsShade(id, shadeId, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/products/${id}/shades/${shadeId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: patchProductVariationsShade(id, shadeId, data),
        });
      });
  };
}

export function deleteProductVariationsShade(id, shadeId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${id}/shades/${shadeId}`,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductVariationsShade(id, shadeId),
        });
      });
  };
}

export function deleteBulkProductVariationsShades(id, data) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/products/${id}/shades/bulk_delete`,
        getConfig(data),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteBulkProductVariationsShades(id, data),
        });
      });
  };
}

export function bulkPostProductVariationsShades(id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/${id}/shades/bulk_create`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: bulkPostProductVariationsShades(id, data),
        });
      });
  };
}

export function singlePostProductVariationsShades(id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/products/${id}/shades`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: singlePostProductVariationsShades(id, data),
        });
      });
  };
}


export function getTableLayout() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/interface`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_TABLE_LAYOUT,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getTableLayout(),
        });
      });
  };
}

export function updateTableLayout(data) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/interface`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_TABLE_LAYOUT,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateTableLayout(data),
        });
      });
  };
}

export function addProductSizes(id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/products/${id}/sizes`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCT_SIZES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: addProductSizes(id, data),
        });

        return error;
      });
  };
}

export function getProductBrands() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/brands`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_BRANDS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductBrands(),
        });
      });
  };
}

export function getInMarketBrands(params) {
  const { offset = 0, isConcat = false, search } = params;
  const limit = `&limit=${PAGINATION_CONSUMERDBS_MAX}`;
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`rest_api/products/brands?offset=${offset}${limit}${searchValue}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_IN_MARKET_BRANDS,
          data: response.data,
          isConcat: isConcat,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getInMarketBrands(params),
        });
      });
  };
}

export function getProductSharedBrands() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/shared/brands`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SHARED_PRODUCT_BRANDS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductSharedBrands(),
        });
      });
  };
}
export function getToxins(params) {
  const { groupId, withComment, productName, productId, isChemicalGroup } = params;
  const getSign = withComment || productName || productId ? '&' : '';
  const groupType = isChemicalGroup ? 'chemical_group_id' : 'group_id';
  const getGroupIdParam = groupId ? `?${groupType}=${groupId}${getSign}` : '';
  const getCommentParam = withComment
    ? `${groupId ? '' : '?'}with_comment=true`
    : '';
  const getProductNameParam = productName
    ? `${
        groupId ? '&' : `${withComment ? '&' : '?'}`
      }product_name=${productName}`
    : '';
  const getProductIdParam = productId
    ? `${
        groupId ? '&' : `${withComment ? '&' : `${productName ? '&' : '?'}`}`
      }product_id=${productId}`
    : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/toxins${getGroupIdParam}${getCommentParam}${getProductNameParam}${getProductIdParam}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_TOXINS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getToxins(params),
        });
      });
  };
}

export function getToxinCategies() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/toxins/categories`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_TOXIN_CATEGORIES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getToxinCategies(),
        });
      });
  };
}

export function createNewFolder(name, user, withGroup) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/folders`, withGroup ? name : { name }, getConfig())
      .then((response) => response.data)
      .then((response) => {
        segmentsTrack({
          user: {
            id: response.user_id,
            ...user,
          },
          name: 'Folder Created',
          description: 'User creates a new folder',
          folder_id: response.id,
        });

        dispatch({
          type: CREATE_NEW_FOLDER,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createNewFolder(name, user, withGroup),
        });
      });
  };
}

export function createNewGroup(name) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/groups`, { name }, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_NEW_FOLDER,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createNewGroup(name),
        });
      });
  };
}

export function getAllPermissions(
  withAllUserPermissions = false,
  withConditions = false,
  withCompanyPermissions = true,
) {
  const allUserPermissions = withAllUserPermissions ? 'with_user_permissions=true&' : '';
  const conditions = withConditions ? 'with_conditions=true&' : '';
  const companyPermissions = withCompanyPermissions ? 'company_permission=true' : '';
  const query = `?user_permission=true&${allUserPermissions}${conditions}${companyPermissions}`;

  return (dispatch) => {
    dispatch(setGetAllPermissionsStarted());

    return axiosInstance
      .get(
        `/rest_api/users/permissions${query}`,
        getConfig()
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: withCompanyPermissions ? GET_ALL_PERMISSIONS : GET_USER_PERMISSIONS,
          data: withCompanyPermissions ? response : response?.user_permissions || [],
        });

        if (withConditions) {
          dispatch({
            type: GET_COMPANY_GRAND_CATEGORIES,
            data: response,
          });
        }
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getAllPermissions(withAllUserPermissions, withConditions, withCompanyPermissions),
        });
      });
  };
}

export function getUserPermissions(userId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/users/${userId}/permissions`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_EDITED_USER_PERMISSIONS,
          data: response.data,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getUserPermissions(userId),
        });
      });
  };
}

export function postUserPermissions(userId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/companies/company/users/${userId}/permissions`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: postUserPermissions(userId, data),
        });

        return error;
      });
  };
}

export function getCompanyPermissions() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/permissions`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY_PERMISSIONS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyPermissions(),
        });
      });
  };
}

export function getUserProfile() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/users/profile`, getConfig())
      .then((response) => {
        return {...response.data, headers: response.headers };
      })
      .then((response, res) => {
        dispatch({
          type: GET_USER_PROFILE,
          data: response,
        });
      })
      .catch((error) => {
        if (
          error?.response?.status === 503 ||
          error?.response?.status === 502
        ) {
          window.document.body.innerHTML = error?.response?.data;
        } else {
          catchFunction({
            error,
            dispatch,
            refreshFunction: getUserProfile(),
          })
        }
      });
  };
}

export function getUserProfileByToken(token) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/auth/refresh`, {
        refresh: token,
      })
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        sessionStorage.setItem(
          'user-info-for-verified',
          JSON.stringify(response),
        );

        return response;
      })
      .catch((error) => {
        dispatch({
          type: GLOBAL_ERROR,
          data: error?.response?.request?.response,
        });
      });
  };
}

export function getAuthProfileUrl(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/auth/login`, data)
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        sessionStorage.setItem(
          'user-info-for-verified',
          JSON.stringify(response),
        );

        return response;
      })
      .catch((error) => {
        dispatch({
          type: GLOBAL_ERROR,
          data: error?.response?.request?.response,
        });
      });
  };
}

export function getNotifications() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/notifications`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_USER_NOTIFICATIONS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error?.response?.status === 503 ||
          error?.response?.status === 502
        ) {
          window.document.body.innerHTML = error?.response?.data;
        } else if (
          error?.response?.status === 401 &&
          error?.response?.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(dispatch(getNotifications()));
        }
      });
  };
}

export function getCompanyProfile() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY_PROFILE,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyProfile(),
        });
      });
  };
}
export function getCompanyTypes() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/types`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY_TYPES,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyTypes(),
        });
      });
  };
}
export function getCompanyBrands() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/brands`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANY_BRANDS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyBrands(),
        });
      });
  };
}
export function getCompanyFormulasBrands() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/brands`, getConfig())
      .then((response) =>  response.data)
      .then((response) => {
        dispatch({
          type: GET_COMPANY_FORMULA_BRANDS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyFormulasBrands(),
        });
      });
  };
}
export function getCompanyCurrencies() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/currencies`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_USER_COMPANY_CURRENCY,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyCurrencies(),
        });
      });
  };
}
export function getCompanyUserRole() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/users/roles`, getConfig())
      .then((response) => {

        return response.data.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyUserRole(),
        });
      });
  };
}
export function getCompanyProfileUsers(params) {
  const {
    offset = 0,
    sort = 'name',
    accumulate = false,
    search = '',
  } = params || {};
  const sortQuery = `&sort=${sort}`;
  const limit = `&limit=${PAGINATION_USER_MAX}`;
  const searchQuery = search ? `&search=${search}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/users?offset=${offset}${sortQuery}${limit}${searchQuery}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        if (accumulate) {
          dispatch({
            type: LOAD_COMPANY_PROFILE_USERS,
            data: response,
          });
        } else {
          dispatch({
            type: GET_COMPANY_PROFILE_USERS,
            data: response,
          });
        }

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanyProfileUsers(params),
        });
      });
  };
}

export function updateUserProfile(data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/users/profile`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_USER_PROFILE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateUserProfile(data),
        });
      });
  };
}

export function updateUserCompany(data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/companies/company`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_USER_COMPANY,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateUserCompany(data),
        });
      });
  };
}

export function getRegulatory() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/toxins/groups`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_REGULATORY,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getRegulatory(),
        });
      });
  };
}

export function createProduct(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/products`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createProduct(data),
        });
      });
  };
}

export function createFormula(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCTS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createFormula(data),
        });
      });
  };
}

export function updateProduct(data, prodId) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/formulator/products/${prodId}`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProduct(data, prodId),
        });
      });
  };
}

export function updateFormula(data, prodId) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/formulator/formulas/${prodId}`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormula(data, prodId),
        });
      });
  };
}

export function updateFormulaStatus(data, prodId) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/formulator/formulas/${prodId}/status`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS_STATUS,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaStatus(data, prodId),
        });
      });
  };
}

export function updateFormulaGroups(data, prodId) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/formulator/formulas/${prodId}/groups`, data, getConfig())
      .then((response) => {
        return {...response.data, headers: response.headers };
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_GROUPS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaGroups(data, prodId),
        });
      });
  };
}

export function updateFolderBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/formulator/products/bulk`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_FOLDER_BULK,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFolderBulk(data),
        });
      });
  };
}

export function formulasLockBulk(data, isSingle) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/lock`, data, getConfig())
      .then((response) => {
        dispatch({
          type: isSingle ? UNLOCK_FORMULATOR_PRODUCT : UPDATE_FOLDER_BULK,
          data: isSingle ? data : response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: formulasLockBulk(data, isSingle),
        });
      });
  };
}

export function formulasMoveBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/move`, data, getConfig())
      .then((response) => {
        dispatch({
          type: UPDATE_FOLDER_BULK,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: formulasMoveBulk(data),
        });
      });
  };
}

export function replaceSmartFormula(parentId) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/${ parentId }/smart-replace`, {}, getConfig())
      .then((response) => {
        dispatch({
          type: REPLACE_SMART_FORMULA,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: replaceSmartFormula(parentId),
        });
      });
  };
}

export function foldersMoveBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/folders/move`, data, getConfig())
      .then((response) => {
        dispatch({
          type: UPDATE_FOLDER_BULK,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: foldersMoveBulk(data),
        });
      });
  };
}

export function deleteProductsBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/formulator/products/bulk`, getConfig({ ids: data }))
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: DELETE_FOLDERS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductsBulk(data),
        });
      });
  };
}

export function deleteFormulasBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/formulator/formulas/bulk`, getConfig(data))
      .then((res) => res)
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteFormulasBulk(data),
        });

        return error;
      });
  };
}

export function deleteCompoundIngredientsBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/compound_ingredient?ingredient_ids=${data.join(
          '&ingredient_ids=',
        )}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: DELETE_FOLDERS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteCompoundIngredientsBulk(data),
        });
      });
  };
}
export function deleteGroup(group_id) {

  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/formulator/groups/${group_id}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteGroup(group_id),
        });
      });
  };
}
export function deleteFoldersBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/formulator/folders`, getConfig(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteFoldersBulk(data),
        });

        return error;
      });
  };
}

export function refreshToken() {
  const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
  const token = user?.refresh;

  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/auth/refresh`, {
        refresh: token,
      })
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        sessionStorage.setItem(
          'user-info-for-verified',
          JSON.stringify(response),
        );
      })
      .catch((error) => {
        dispatch({
          type: GLOBAL_ERROR,
          data: error?.response?.request?.response,
        });
      });
  };
}

export function updateFolderName({ folderId, folderName }) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/folders/${folderId}`,
        { name: folderName },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFolderName({ folderId, folderName }),
        });
      });
  };
}

export function updateGroupName({ groupId, groupName }) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/groups/${groupId}`,
        {
          name: groupName,
        },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateGroupName({ groupId, groupName }),
        });
      });
  };
}

export function updateProductIngredients(productsId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${productsId}/ingredients`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_INGREDIENTS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductIngredients(productsId, data),
        });
      });
  };
}

export function updateFormulaParams(productsId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productsId}/params`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_PARAMS,
          data: response.data,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaParams(productsId, data),
        });
      });
  };
}

export function updateSmartFormulaParams(productsId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productsId}/smart-params`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_PARAMS,
          data: response.data,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateSmartFormulaParams(productsId, data),
        });
      });
  };
}

export function breakSmartFormula(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productId}/smart-params/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: BREAK_SMART_FORMULA,
          data: response.data,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: breakSmartFormula(productId, ingredientId, data),
        });
      });
  };
}

export function getFormulatorProduct(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/products/${productId}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_PRODUCT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulatorProduct(productId),
        });
      });
  };
}

export function getFormulaDraftId(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/smart-edit`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_DRAFT_ID,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaDraftId(productId),
        });
      });
  };
}

export function getFormula(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}`, getConfig())
      .then((response) => {
        return {...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_PRODUCT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormula(productId),
        });
      });
  };
}

export function getFormulaParams(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/params`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_PARAMS,
          data: response.data,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaParams(productId),
        });
      });
  };
}

export function getFormulaTotalConcentration(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-concentration`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalConcentration(formulaId),
        });
      });
  };
}
export function getFormulaTotalBiodegradability(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-biodegradability`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_BIODEGRADABILITY,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalBiodegradability(formulaId),
        });
      });
  };
}
export function getFormulaTotalCost(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-cost`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_COST,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalCost(formulaId),
        });
      });
  };
}

export function getFormulaTotalNaturality(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-naturality`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_NATURALITY,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalNaturality(formulaId),
        });
      });
  };
}

export function getFormulaTotalNaturalOrigin(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-natural-origin`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_NATURAL_ORIGIN,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalNaturalOrigin(formulaId),
        });
      });
  };
}

export function getFormulaTotalOrganicContent(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-organic-content`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_CONTENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalOrganicContent(formulaId),
        });
      });
  };
}

export function getFormulaTotalOrganicOrigin(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/total-organic-origin`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_TOTAL_ORGANIC_ORIGIN,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaTotalOrganicOrigin(formulaId),
        });
      });
  };
}

export function getFormulaCompoundParams(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/compound-params`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_COMPOUND_PARAMS,
          data: response.data,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaCompoundParams(productId),
        });
      });
  };
}

export function getFormulaSmartFormulas(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/smart-params`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULATOR_FORMULA_SMART_FORMULAS,
          data: response.data,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaSmartFormulas(productId),
        });
      });
  };
}

export function getFormulaCompoundParamIngredients(productId, param_id, isSmartChild) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/compound-params/${param_id}`, getConfig())
      .then((response) => {
        dispatch({
          type: GET_FORMULA_COMPOUND_PARAM_INGREDIENTS,
          data: response.data,
          isSmartChild: isSmartChild,
        });

        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaCompoundParamIngredients(productId, param_id),
        });
      });
  };
}

export function getFormulaSmartFormulaIngredients(productId, param_id) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/smart-params/${param_id}`, getConfig())
      .then((response) => {
        dispatch({
          type: GET_FORMULA_SMART_FORMULA_INGREDIENTS,
          data: response.data,
        });

        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaSmartFormulaIngredients(productId, param_id),
        });
      });
  };
}

export function isFormulatorProductHasNFBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/products/bulk/has-nf`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: isFormulatorProductHasNFBulk(data),
        });
      });
  };
}

export function isFormulasHasNFBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/send-nf-review`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: isFormulasHasNFBulk(data),
        });
      });
  };
}

export function isFormulatorProductHasNF(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/products/${productId}/has-nf`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: isFormulatorProductHasNF(productId),
        });
      });
  };
}

export function deleteCompanyUser(userId) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/companies/company/users/${userId}`, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: DELETE_COMPANY_USER,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteCompanyUser(userId),
        });
      });
  };
}

export function activateCompanyUser(userId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/users/${userId}/activate`,
        {},
        getConfig()
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: activateCompanyUser(userId),
        });
      });
  };
}

export function deactivateCompanyUser(userId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/users/${userId}/deactivate`,
        {},
        getConfig()
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deactivateCompanyUser(userId),
        });
      });
  };
}

export function sendInviteNewUser(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/companies/company/users/send-invite`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: sendInviteNewUser(data),
        });
      });
  };
}

export function resetCompanyUserPassword(userId) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/users/password/reset`, { user_id: userId }, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_INGREDIENTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: resetCompanyUserPassword(userId),
        });
      });
  };
}

export function getTeams(sort = 'name') {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/teams?sort=${sort}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getTeams(sort = 'name'),
        });
      });
  };
}

export function createTeam(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/teams`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createTeam(data),
        });

        return error.response;
      });
  };
}

export function saveTeam(data, teamId) {
  return (dispatch) => {
    return axiosInstance
      .put(`/rest_api/teams/${teamId}`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: saveTeam(data, teamId),
        });

        return error.response;
      });
  };
}

export function deleteTeam(teamId) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/teams/${teamId}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteTeam(),
        });
      });
  };
}

export function getProductBenefits() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/products/benefits`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_BENEFITS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductBenefits(),
        });
      });
  };
}

export function getIngredients(isForBenefits = false, benefit) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/ingredients${
          isForBenefits
            ? `?min_benefit=6&max_benefit=10&limit=10&benefit=${benefit}`
            : ''
        }`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_INGREDIENTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getIngredients((isForBenefits = false), benefit),
        });
      });
  };
}

export function requestReviewIngredient(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/ingredients/review`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_RM,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(requestReviewIngredient(data)),
          );
        } else {
          if (error.response.status === 403) {
            getAllPermissions();
          }

          dispatch({
            type: GLOBAL_ERROR,
            request: 'requestReviewIngredient',
            data: error.response.request.response,
          });
        }
      });
  };
}

export function requestReviewIngredientsBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/compound_ingredient/send-nf-review`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_RM,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(requestReviewIngredientsBulk(data)),
          );
        } else {
          if (error.response.status === 403) {
            getAllPermissions();
          }

          dispatch({
            type: GLOBAL_ERROR,
            request: 'requestReviewIngredient',
            data: error.response.request.response,
          });
        }
      });
  };
}

export function requestReviewIngredientNew(data, compoundId) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/ingredients/review`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_REQUEST_REVIEW_INGREDIENT,
          data: {
            resp: response,
            compoundId: compoundId,
          },
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestReviewIngredientNew(data, compoundId),
        });
      });
  };
}

// Temporary for Consumer, later can be used for all other places, to check, if review already been requested?
export function checkIsReviewRequestedIngredients(ingredient_ids) {
  const query = stringify(
    { ingredient_ids: ingredient_ids },
    { arrayFormat: 'repeat' },
  );

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients/review/check?${query}`, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(checkIsReviewRequestedIngredients(ingredient_ids)),
          );
        } else {
          if (error.response.status === 403) {
            getAllPermissions();
          }

          dispatch({
            type: GLOBAL_ERROR,
            request: 'requestReviewIngredient',
            data: error.response.request.response,
          });
        }
      });
  };
}

export function requestReviewSupplier(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/suppliers/review`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_SUPPLIER,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestReviewSupplier(data),
        });
      });
  };
}

export function requestReviewManufacturer(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/manufacturers/review`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_MANUFACTURERS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestReviewManufacturer(data),
        });
      });
  };
}

export function createNewTags(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/tags/bulk`, data, getConfig())
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_MANUFACTURERS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(dispatch(createNewTags(data)));
        } else {
          if (error.response.status === 403) {
            getAllPermissions();
          }

          dispatch({
            type: GLOBAL_ERROR,
            data: error.response.request.response,
          });

          return error.response.request.response;
        }
      });
  };
}

export function getBlackList(offset = 0, search, sort) {
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const sortQuery = sort ? `&sort=${sort}` : '';
  const limit = `&limit=${PAGINATION_RAWMATERIAL_MAX}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/blacklists?offset=${offset}${limit}${searchQuery}${sortQuery}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_BLACK_LIST,
          data: response,
        });

        return response.data
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(getBlackList((offset = 0), search, sort)),
          );
        } else if (error.response.status === 403) {
          dispatch({
            type: FORBIDDEN_BLACKLIST,
            data: error.response.request.response,
          });
          getAllPermissions();
        } else {
          dispatch({
            type: GLOBAL_ERROR,
            data: error.response.request.response,
          });
        }
      });
  };
}

export function getPolicyLink() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/blacklists/privacy_policy`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPolicyLink(),
        });
      });
  };
}
export function getBlackListById(params) {
  const {id, sort, search, offset, limit = 100} = params
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const sortQuery = sort ? `&sort=${sort}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/blacklists/${id}/items?offset=${offset}&limit=${limit}${sortQuery}${searchQuery}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_BLACK_LIST_ITEMS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getBlackListById(params),
        });
      });
  };
}

export function updateBlackListItem(itemId, data, blackListId) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/blacklists/${blackListId}/items/${itemId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_BLACK_LIST_ITEM,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateBlackListItem(itemId, data, blackListId),
        });
      });
  };
}

export function copyToConsumerDBS(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/products/copy_to_consumer`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: copyToConsumerDBS(data),
        });
      });
  };
}

export function copyFormulaToConsumerDB(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/copy`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: copyFormulaToConsumerDB(data),
        });
      });
  };
}

export function recopyToConsumerDBS(product_id, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/products/${product_id}/recopy_to_consumer`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: recopyToConsumerDBS(product_id, data),
        });
      });
  };
}

export function recopyFormulaToConsumerDB(product_id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${product_id}/recopy`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: recopyFormulaToConsumerDB(product_id, data),
        });
      });
  };
}

export function getPartnerShips(params) {
  const {
    type = '',
    search = '',
    partner_ids = [],
    category = [],
    subcategory = [],
    product_type = [],
    shared_date = '',
    toxin_group_ids = [],
    is_retailer,
  } = params;
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const partnerIds = partner_ids.length
    ? `&partner_ids=${partner_ids.join('&partner_ids=')}`
    : '';
  const categoryQuery = category.length
    ? `&category_ids=${category.join('&category_ids=')}`
    : '';
  const subcategoryQuery = subcategory.length
    ? `&subcategory_ids=${subcategory.join('&subcategory_ids=')}`
    : '';
  const productType = product_type?.length
    ? `&product_type_ids=${product_type.join('&product_type_ids=')}`
    : '';
  const partnership_type = type ? `&partnership_type=${type}` : '';
  const sharedDateQuery = shared_date ? `&shared_date=${shared_date}` : '';
  const complianceQuery = toxin_group_ids.length
    ? `&toxin_group_ids=${toxin_group_ids.join('&toxin_group_ids=')}`
    : '';
  const isRetailer = is_retailer ? `&is_retailer=true` : '';
  const filters = `${partnerIds}${categoryQuery}${subcategoryQuery}${productType}${sharedDateQuery}${complianceQuery}${isRetailer}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/companies/company/partnerships?offset=0${partnership_type}${searchQuery}${filters}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PARTNERSHIPS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPartnerShips(params),
        });
      });
  };
}

export function getPartnerShipsSharedItems(data) {
  const searchQuery = data.search ? `&search=${data.search}` : '';
  const categoryQuery = data.category?.length
    ? `&category_ids=${data.category.join('&category_ids=')}`
    : '';
  const subcategoryQuery = data.subcategory?.length
    ? `&subcategory_ids=${data.subcategory.join('&subcategory_ids=')}`
    : '';
  const productType = data.product_type?.length
    ? `&product_type_ids=${data.product_type.join('&product_type_ids=')}`
    : '';
  const sharedDateQuery = data.shared_date ? `&shared_date=${data.shared_date}` : '';
  const complianceQuery = data.toxin_group_ids?.length
    ? `&toxin_group_ids=${data.toxin_group_ids.join('&toxin_group_ids=')}`
    : '';

  const filters = `${searchQuery}${categoryQuery}${subcategoryQuery}${productType}${sharedDateQuery}${complianceQuery}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/companies/company/partnerships/${data.partnershipId}/shared-items?offset=${data.offset}${filters}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PARTNERSHIPS_SHARED_ITEMS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPartnerShipsSharedItems(data),
        });
      });
  };
}

export function refreshSharedProduct(product_id, shared_item_id, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/formulator/products/${product_id}/shared-items/${shared_item_id}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REFRESH_SHARED_PRODUCT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: refreshSharedProduct(
            product_id,
            shared_item_id,
            data,
          ),
        });
      });
  };
}

export function refreshSharedFormula(formula_id, shared_item_id, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${formula_id}/share/${shared_item_id}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REFRESH_SHARED_PRODUCT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: refreshSharedFormula(
            formula_id,
            shared_item_id,
            data,
          ),
        });
      });
  };
}

export function shareFormulaProductsBulkWithPartners(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/shared-items/bulk`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: SHARE_FORMULA_PRODUCTS_BULK_WITH_PARTNERS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: shareFormulaProductsBulkWithPartners(data),
        });
      });
  };
}

export function shareOrRefreshFormulasWithPartnersBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/share-sync`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: SHARE_FORMULA_PRODUCTS_BULK_WITH_PARTNERS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: shareOrRefreshFormulasWithPartnersBulk(data),
        });
      });
  };
}

export function deleteSharedFormulaProductsBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/products/shared-items/bulk`,
        getConfig(data),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteSharedFormulaProductsBulk(data),
        });
      });
  };
}

export function deleteSharedFormulasBulk(data) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/formulas/unshare-all`,
        getConfig(data),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteSharedFormulasBulk(data),
        });
      });
  };
}

export function deleteSharedFormulaProduct(product_id, shared_item_id) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/products/${product_id}/shared-items/${shared_item_id}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteSharedFormulaProduct(
            product_id,
            shared_item_id,
          ),
        });
      });
  };
}

export function unshareSharedFormula(formula_id, shared_item_id) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/formulas/${formula_id}/share/${shared_item_id}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: unshareSharedFormula(
            formula_id,
            shared_item_id,
          ),
        });
      });
  };
}

export function removeCustomTag(tagId) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/tags/${tagId}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeCustomTag(tagId),
        });
      });
  };
}

export function removeCustomTags(tagIds) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/tags/bulk`, getConfig({ tags_ids: tagIds }))
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DELETE_SHARED_FORMULA_PRODUCTS_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeCustomTags(tagIds),
        });
      });
  };
}
export function getFormulatorCompoundIngredients(params) {
  const {
    offset = 0,
    search,
    sort,
    function_ids = [],
    manufacturer_ids = [],
    application_ids = [],
    certification_ids = [],
    tag_ids = [],
    document_tag_ids = [],
    price_from,
    price_to,
    naturality_from,
    naturality_to,
    toxin_group_ids = [],
    black_list_ids= [],
    status= [],
    rn_search,
    replacement_group_ids = [],
    tnl_id,
    manufacturer_id,
    formulator_search,
  } = params;
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const sortQuery = sort ? `&sort=${sort}` : '';
  const functionIds = function_ids.length
    ? `&function_ids=${function_ids.join('&function_ids=')}`
    : '';
  const manufacturerIds = manufacturer_ids.length
    ? `&manufacturers_ids=${manufacturer_ids.join('&manufacturers_ids=')}`
    : '';
  const applicationIds = application_ids.length
    ? `&application_ids=${application_ids.join('&application_ids=')}`
    : '';
  const certificationIds = certification_ids.length
    ? `&certification_ids=${certification_ids.join('&certification_ids=')}`
    : '';
  const tagIds = tag_ids.length ? `&tag_ids=${tag_ids.join('&tag_ids=')}` : '';
  const documentTagIds = document_tag_ids.length
    ? `&document_tag_ids=${document_tag_ids.join('&document_tag_ids=')}`
    : '';
  const complianceIds = toxin_group_ids.length
    ? `&toxin_group_ids=${toxin_group_ids.join('&toxin_group_ids=')}`
    : '';
  const statusIds = status.length
    ? `&status=${status.join('&status=')}`
    : '';
  const replacementGroups = replacement_group_ids.length
    ? `&replacement_group_ids=${replacement_group_ids.join(
        '&replacement_group_ids=',
      )}`
    : '';
  const priceFrom = price_from >= 0 ? `&price_from=${price_from}` : '';
  const priceTo = price_to >= 0 ? `&price_to=${price_to}` : '';
  const naturalityFrom = naturality_from >= 0 ? `&naturality_from=${naturality_from}` : '';
  const naturalityTo = naturality_to >= 0 ? `&naturality_to=${naturality_to}` : '';
  const limit = `&limit=${PAGINATION_RAWMATERIAL_MAX}`;
  const formulatorSearchParam =
      formulator_search  ? `&formulator_search=true` : '';

  const rnSearch = rn_search ? `&rn_search=true` : '';
  const tnlId = tnl_id ? `&tnl_copy_ids=${tnl_id}` : '';
  const manufacturerId = manufacturer_id
    ? `&manufacturers_ids=${manufacturer_id}`
    : '';
    const blackListIds = black_list_ids.length
    ? `&black_list_ids=${black_list_ids.join(
        '&black_list_ids=',
      )}`
    : '';
  const filters = `${sortQuery}${functionIds}${manufacturerIds}${applicationIds}${certificationIds}${tagIds}${documentTagIds}${priceFrom}${priceTo}${naturalityFrom}${naturalityTo}${complianceIds}${blackListIds}${statusIds}${replacementGroups}${rnSearch}${formulatorSearchParam}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/compound_ingredient?offset=${offset}${limit}${searchQuery}${filters}${tnlId}${manufacturerId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPOUND_INGREDIENTS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulatorCompoundIngredients(params),
        });
      });
  };
}

export function getFormulatorSmartFormulas(params) {
  const {
    offset = 0,
    search,
  } = params;
  const searchQuery = search ? `&search=${encodeURIComponent(search)}` : '';
  const limit = `&limit=${PAGINATION_RAWMATERIAL_MAX}`;

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/smart?offset=${offset}${limit}${searchQuery}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SMART_FORMULAS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulatorSmartFormulas(params),
        });
      });
  };
}

export function getReplacementGroups(data) {
  const searchQuery = data.search ? `&search=${encodeURIComponent(data.search)}` : '';
  const offset = `offset=${data.offset || 0}`;
  const limit = data.limit ? `&limit=${data.limit}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/replacement-groups?${offset}${limit}${searchQuery}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getReplacementGroups(data),
        });
      });
  };
}

export function getSingleReplacementGroup(id) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/replacement-groups/${id}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSingleReplacementGroup(id),
        });
      });
  };
}

export function createReplacementGroup(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/replacement-groups`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createReplacementGroup(data),
        });
      });
  };
}

export function updateReplacementGroup(id, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/replacement-groups/${id}`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateReplacementGroup(data),
        });
      });
  };
}

export function deleteReplacementGroup(groupId) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/replacement-groups/${groupId}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteReplacementGroup(groupId),
        });
      });
  };
}

export function formulaReplacementGroupReplace(formulaId, paramId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/formulas/${formulaId}/compound-params/${paramId}/replace`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createReplacementGroup(data),
        });
      });
  };
}

export function setReplacementGroupToRM(id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/compound_ingredient/${id}/set_replacement_group`, data, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: setReplacementGroupToRM(id, data),
        });
      });
  };
}


export function getIngredintsForLibrary(search) {
  const searchExist = search ? `&search=${search}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients?is_library=true${searchExist}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_INGREDIENTS_FOR_LIBRARY,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getIngredintsForLibrary(search),
        });
      });
  };
}

export function getIngredient(ingredientId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients/${ingredientId}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getIngredient(ingredientId),
        });
      });
  };
}

export function copyIngredient(ingredientId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients/${ingredientId}/copy`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: COPY_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: copyIngredient(ingredientId),
        });
      });
  };
}

export function validateCode(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/partnerships/code`,
        { validation_code: data },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: validateCode(data),
        });
      });
  };
}

export function sendInvitation(partnershipId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/partnerships/send-invitation`,
        { partnership_id: partnershipId },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: sendInvitation(partnershipId),
        });
      });
  };
}

export function acceptInvitation(partnershipId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/partnerships/accept-invitation`,
        { partnership_id: partnershipId },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: acceptInvitation(partnershipId),
        });
      });
  };
}

export function declineInvitation(partnershipId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/partnerships/decline-invitation`,
        { partnership_id: partnershipId },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: declineInvitation(partnershipId),
        });
      });
  };
}

export function removePartnership(partnershipId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/companies/company/partnerships/${partnershipId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: REMOVE_PARNTERSHIP,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removePartnership(partnershipId),
        });
      });
  };
}

export function getCompanies(search) {
  const searchValue = search ? `?search=${search}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/search${searchValue}&add_partnerships=true&have_partnerships=true`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPANIES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompanies(search),
        });
      });
  };
}

export function createPartnership(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/partnerships`,
        { partner_company: data },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REQUEST_REVIEW_INGREDIENT,
          data: response,
        });

        return REQUEST_REVIEW_INGREDIENT
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createPartnership(data),
        });
      });
  };
}

export function deleteBlackListItem(blackListId, itemId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/blacklists/${blackListId}/items/${itemId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REMOVE_BLACKLIST_ITEM,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteBlackListItem(blackListId, itemId),
        });
      });
  };
}

export function getCompoundIngredient(ingredientId, isLightMode) {
  const isLightModeParam = isLightMode ? `?is_light_mode=true` : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/compound_ingredient/${ingredientId}${isLightModeParam}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompoundIngredient(ingredientId, isLightMode),
        });
      });
  };
}

export function getCompoundIngredientParam(data) {
  let paramType = 'inci';
  let asssociatedParam = '';
  let paramAssociated = '';
  let asssociatedParamId = '';
  let associatedParamType = '';
  let paramResult = GET_COMPOUND_INGREDIENT_PARAM;

  switch (data.ingType) {
    case INGREDIENT_TYPE.IMPURITIES:
      paramType = 'impurity';
      paramResult = GET_COMPOUND_INGREDIENT_IMPUTITY;
      break;
    case INGREDIENT_TYPE.ASSOCIATED_INCI:
      paramType = 'inci';
      paramAssociated = '/associated';
      paramResult = GET_COMPOUND_INGREDIENT_ASSOCIATED_INCI;
      asssociatedParam = data.allergenName
        ? `&raw_ingredient=${data.allergenName}`
        : '';
      asssociatedParamId = data.allergenId
        ? `&ingredient_id=${data.allergenId}`
        : '';
      associatedParamType = data.associatedParamType
        ? `&ingredient_param_type=${
            ASSOCIATED_INGREDIENT_TYPE[data.associatedParamType]
          }`
        : '';
      break;
    case INGREDIENT_TYPE.ALLERGENS:
      paramType = 'allergen';
      paramResult = GET_COMPOUND_INGREDIENT_ALLERGEN;
      break;
    case INGREDIENT_TYPE.INCIDENTAL:
      paramType = 'incidental';
      paramResult = GET_COMPOUND_INGREDIENT_INCIDENTAL;
      break;
    default:
      break;
  }

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/compound_ingredient/${data.ingredientId}/param${paramAssociated}?param_type=${paramType}${asssociatedParam}${asssociatedParamId}${associatedParamType}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: paramResult,
          data: response,
        });

        if (data.ingType === INGREDIENT_TYPE.ASSOCIATED_INCI) {
          return response;
        }
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCompoundIngredientParam(data),
        });
      });
  };
}

export function getPhases(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/products/${productId}/phases`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PHASES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPhases(productId),
        });
      });
  };
}

export function getFormulaPhases(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/phases`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PHASES,
          data: response.data,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaPhases(productId),
        });
      });
  };
}

export function getSmartFormulaPhases(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/smart-phases`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSmartFormulaPhases(productId),
        });
      });
  };
}

export function createPhase(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${productId}/phases`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: CREATE_PHASE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createPhase(productId, data),
        });
      });
  };
}

export function createFormulaPhase(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productId}/phases`,
        data,
        getConfig(),
      )
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })

      .then((response) => {
        dispatch({
          type: CREATE_PHASE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createFormulaPhase(productId, data),
        });
      });
  };
}

export function updatePhase(productId, data, phaseId) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/formulator/products/${productId}/phases/${phaseId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_FOLDER_BULK,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updatePhase(productId, data, phaseId),
        });
      });
  };
}

export function updateFormulaPhase(productId, data, phaseId) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${productId}/phases/${phaseId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })

      .then((response) => {
        dispatch({
          type: UPDATE_FOLDER_BULK,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaPhase(productId, data, phaseId),
        });
      });
  };
}

export function removeProductPhase(productId, phaseId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/products/${productId}/phases/${phaseId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REMOVE_BLACKLIST_ITEM,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeProductPhase(productId, phaseId),
        });
      });
  };
}

export function removeFormulaPhase(productId, phaseId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/formulas/${productId}/phases/${phaseId}`,
        getConfig(),
      )
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: REMOVE_BLACKLIST_ITEM,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeFormulaPhase(productId, phaseId),
        });
      });
  };
}

export function getReports({ offset = 0, isLightMode = false }) {
  const limit = `&limit=${PAGINATION_REPORTS_MAX}`;
  const lightModeParam = isLightMode ? `&is_light_mode=true` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/reports/history?offset=${offset}${limit}${lightModeParam}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_REPORTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getReports({ offset, isLightMode }),
        });
      });
  };
}

export function downloadReport(reportId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/reports/history/${reportId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DOWNLOAD_REPORT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: downloadReport(reportId),
        });
      });
  };
}

export function deleteReport(reportId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/reports/history/${reportId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REMOVE_REPORT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteReport(reportId),
        });
      });
  };
}

export function generateReportsXLS(ingIds, hasNf) {
  const hasNfParam = hasNf ? '&has_nf=true' : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/compound_ingredient/xls_report?ingredient_ids=${ingIds.join(
          '&ingredient_ids=',
        )}${hasNfParam}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DOWNLOAD_REPORT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: generateReportsXLS(ingIds, hasNf),
        });
      });
  };
}

export function generateProductsReports(data, isNewApi = false) {
  const url = isNewApi
    ? `/rest_api/formulator/formulas/xls-report`
    : `/rest_api/formulator/products/xls_report`;

  return (dispatch) => {
    return axiosInstance
      .post(url, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GENERATE_PRODUCTS_REPORTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: generateProductsReports(data, isNewApi = false),
        });

        return error
      });
  };
}

export function generateProductsPdfReports(ingredient_id) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/compound_ingredient/${ingredient_id}/pdf_report?has_nf=true`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GENERATE_PRODUCTS_REPORTS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: generateProductsPdfReports(ingredient_id),
        });
      });
  };
}

export function updateProductIngredient(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/formulator/products/${productId}/ingredients/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductIngredient(
            productId,
            ingredientId,
            data,
          ),
        });
      });
  };
}

export function updateFormulaIngredient(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${productId}/params/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_FORMULA_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaIngredient(
            productId,
            ingredientId,
            data,
          ),
        });
      });
  };
}

export function updateProductCompoundChildIngredient(data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/products/${data.productId}/compound-ingredient-params/${data.ingredientId}/compound-ingredient-child/${data.child_id}`,
        data.data,
        getConfig(),
      )
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_INGREDIENT_TAG,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductCompoundChildIngredient(data),
        });
      });
  };
}

export function updateFormulaCompoundChildIngredient(data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${data.productId}/compound-params/${data.ingredientId}/child/${data.child_id}`,
        data.data,
        getConfig(),
      )
      .then((response) => {
        dispatch({
          type: UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT,
          data: {
            ingredientId: data.ingredientId,
            updatedChild: response.data,
          },
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaCompoundChildIngredient(data),
        });
      });
  };
}

export function updateProductCompoundIngredient(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/products/${productId}/compound-ingredient-params/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductCompoundIngredient(
            productId,
            ingredientId,
            data,
          ),
        });
      });
  };
}

export function updateFormulaCompoundIngredient(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${productId}/compound-params/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_FORMULA_INGREDIENT_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaCompoundIngredient(
            productId,
            ingredientId,
            data,
          ),
        });
      });
  };
}
export function updateFormulaSmartIngredient(productId, ingredientId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/formulas/${productId}/smart-params/${ingredientId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_FORMULA_SMART_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaSmartIngredient(
            productId,
            ingredientId,
            data,
          ),
        });
      });
  };
}

export function deleteProductCompoundIngredientBulk(productId, ingredientIds) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/products/${productId}/compound-ingredient-params`,
        getConfig({ compound_ids: ingredientIds }),
      )
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch({
          type: DELETE_RM,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductCompoundIngredientBulk(
            productId,
            ingredientIds,
          ),
        });
      });
  };
}

export function deleteFormulaCompoundIngredientBulk(productId, ingredientIds) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/formulas/${productId}/compound-params`,
        getConfig({ compound_ids: ingredientIds }),
      )
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch({
          type: DELETE_FORMULA_INGREDIENT_COMPOUND,
          data: ingredientIds,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteFormulaCompoundIngredientBulk(
            productId,
            ingredientIds,
          ),
        });
      });
  };
}

export function deleteFormulaSmartFormulasBulk(productId, ingredientIds) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/formulas/${productId}/smart-params`,
        getConfig({ smart_ids: ingredientIds }),
      )
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch({
          type: DELETE_FORMULA_INGREDIENT_COMPOUND,
          data: ingredientIds,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteFormulaSmartFormulasBulk(
            productId,
            ingredientIds,
          ),
        });
      });
  };
}

export function getFunctionsList(search, showOTC) {
  const searchValue = search ? `&search=${search}` : '';
  const excludeValue = `exclude_for_rm=${!showOTC || false}`;

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/ingredients/functions?${excludeValue}${searchValue}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FUNCTIONS_LIST,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFunctionsList(search, showOTC),
        });
      });
  };
}

export function getSharedProduct(partnerId, itemId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/companies/company/partnerships/${partnerId}/shared-items/${itemId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SHARED_PRODUCT,
          data: response.id ? response : {},
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSharedProduct(partnerId, itemId),
        });
      });
  };
}

export function deleteCompoundIngredient(ingredientId) {
  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/compound_ingredient/${ingredientId}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REMOVE_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteCompoundIngredient(ingredientId),
        });
      });
  };
}

export function duplicateCompoundIngredient(ingredientId) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/compound_ingredient/${ingredientId}/duplicate`,
        {},
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: DUPLICATE_COMPOUND_INGREDIENT,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: duplicateCompoundIngredient(ingredientId),
        });
      });
  };
}

export function getCertifications() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/certifications`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_CERTIFICATIONS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCertifications(),
        });
      });
  };
}

export function getSuppliers(offset = 0, search = '') {
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/suppliers?offset=${offset}${searchValue}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SUPPLIERS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSuppliers((offset = 0), (search = '')),
        });
      });
  };
}

export function getManufacturers(offset = 0, search = '', by_raw_materials = false, by_tnls = false) {
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';
  const rawMaterialsParam = by_raw_materials ? '&by_raw_materials=true' : '';
  const tnlParam = by_tnls ? '&by_tnls=true' : '';

  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/manufacturers?offset=${offset}${searchValue}${rawMaterialsParam}${tnlParam}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_MANUFACTURERS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getManufacturers(offset = 0, search = '', by_raw_materials = false, by_tnls = false),
        });
      });
  };
}

export function updateCompoundIngredient(ingId, data) {
  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/compound_ingredient/${ingId}`,
        data,
        getConfig(),
      )
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPDATE_COMPOUND_INGREDIENT,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateCompoundIngredient(ingId, data),
        });
      });
  };
}
export function getMaxPrice() {
  return (dispatch) => {
    return axiosInstance
      .get('rest_api/formulator/compound_ingredient/max_price', getConfig())
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: GET_MAX_PRICE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getMaxPrice(),
        });
      });
  };
}
export function getCountInFormulas(RMId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/raw-materials/${RMId}/count-formulas`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCountInFormulas(RMId),
        });
      });
  };
}
export function updateCompoundIngredientStatus(ingId, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(
        `/rest_api/raw-materials/${ingId}/status`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_COMPOUND_INGREDIENT_STATUS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateCompoundIngredientStatus(ingId, data),
        });
      });
  };
}

export function getCountries() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/countries`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_COUNTRIES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCountries(),
        });
      });
  };
}

export function getRegions() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/regions`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_REGIONS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getRegions(),
        });
      });
  };
}

export function getSources() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients/sources`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SOURCES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSources(),
        });
      });
  };
}

export function getOrigins() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/ingredients/origins`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_ORIGINS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getOrigins(),
        });
      });
  };
}

export function getFileTypes() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/file_categories`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FILE_TYPES,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFileTypes(),
        });
      });
  };
}

export function getResponsiblePartyList() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/responsible-party`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getResponsiblePartyList(),
        });
      });
  };
}

export function createResponsibleParty(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/companies/company/responsible-party`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createResponsibleParty(data),
        });
      });
  };
}

export function updateResponsibleParty(id, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/companies/company/responsible-party/${id}`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateResponsibleParty(id, data),
        });
      });
  };
}

export function deleteResponsibleParty(id) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/companies/company/responsible-party/${id}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteResponsibleParty(id),
        });
      });
  };
}

export function getAuthorizedAgentList() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/company/authorized-agent`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getAuthorizedAgentList(),
        });
      });
  };
}

export function createAuthorizedAgent(data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/companies/company/authorized-agent`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createAuthorizedAgent(data),
        });
      });
  };
}

export function updateAuthorizedAgent(id, data) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/companies/company/authorized-agent/${id}`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateAuthorizedAgent(id, data),
        });
      });
  };
}

export function deleteAuthorizedAgent(id) {
  return (dispatch) => {
    return axiosInstance
      .delete(`/rest_api/companies/company/authorized-agent/${id}`, getConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteAuthorizedAgent(id),
        });
      });
  };
}

export function getFileTags(offset = 0, search = '') {
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/tags?type_name=Doc&offset=${offset}${searchValue}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FILE_TAGS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFileTags(offset = 0, search = ''),
        });
      });
  };
}

export function getTags(offset = 0, search = '') {
  const searchValue = search ? `&search=${encodeURIComponent(search)}` : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/tags/bulk?offset=${offset}${searchValue}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_TAGS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getTags((offset = 0), (search = '')),
        });
      });
  };
}

export function getCustomTags(offset = 0, search = '') {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/tags/bulk?type_name=Company Raw Material`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_CUSTOM_TAGS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getCustomTags(offset, search),
        });
      });
  };
}

export function createCompoundIngredient(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/formulator/compound_ingredient`, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createCompoundIngredient(productId, data),
        });
      });
  };
}

export function updateCompoundIngredientParam(data) {
  let paramType = 'inci';

  switch (data.ingType) {
    case INGREDIENT_TYPE.IMPURITIES:
      paramType = 'impurity';
      break;
    case INGREDIENT_TYPE.ALLERGENS:
      paramType = 'allergen';
      break;
    case INGREDIENT_TYPE.INCIDENTAL:
      paramType = 'incidental';
      break;
    default:
      break;
  }

  const paramTypeQuery = `?param_type=${paramType}`;

  return (dispatch) => {
    return axiosInstance
      .put(
        `/rest_api/formulator/compound_ingredient/${data.compoundId}/param/${data.ingredientId}${paramTypeQuery}`,
        data.params,
        getConfig(),
      )
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: UPDATE_PERCENTAGE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateCompoundIngredientParam(data),
          })
      });
  };
}

export function addCompoundIngredientParam(data) {
  let paramType = 'inci';

  switch (data.ingType) {
    case INGREDIENT_TYPE.IMPURITIES:
      paramType = 'impurity';
      break;
    case INGREDIENT_TYPE.ALLERGENS:
      paramType = 'allergen';
      break;
    case INGREDIENT_TYPE.INCIDENTAL:
      paramType = 'incidental';
      break;
    default:
      break;
  }

  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/compound_ingredient/${data.compoundId}/param?param_type=${paramType}`,
        data.params,
        getConfig(),
      )
      .then((response) => response.data)
      .then((response) => {
        dispatch({
          type: UPDATE_PERCENTAGE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(addCompoundIngredientParam(data)),
          );
        } else {
          dispatch({
            type: GLOBAL_ERROR,
            data: error.response.request.response,
          });

          return error.response.request.response;
        }
      });
  };
}

export function deleteCompoundIngredientParam(data) {
  let paramType = 'inci';

  switch (data.ingType) {
    case INGREDIENT_TYPE.IMPURITIES:
      paramType = 'impurity';
      break;
    case INGREDIENT_TYPE.ALLERGENS:
      paramType = 'allergen';
      break;
    case INGREDIENT_TYPE.INCIDENTAL:
      paramType = 'incidental';
      break;
    default:
      break;
  }

  return (dispatch) => {
    return axiosInstance
      .delete(
        `/rest_api/formulator/compound_ingredient/${
          data.compoundId
        }/param?param_type=${paramType}&ids=${data.ingredientIds.join(
          '&ids=',
        )}`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })

      .then((response) => {
        dispatch({
          type: UPDATE_PERCENTAGE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        if (
          error.response.status === 401 &&
          error.response.statusText ===
            'Invalid authorization token, Signature has expired'
        ) {
          dispatch(refreshToken()).then(
            dispatch(deleteCompoundIngredientParam(data)),
          );
        } else {
          dispatch({
            type: GLOBAL_ERROR,
            data: error.response.request.response,
          });

          return error.response.request.response;
        }
      });
  };
}

export function getRestrictionAnalyze(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/products/${productId}/restriction-analyze`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_RESTRICTION_ANALYZE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getRestrictionAnalyze(productId),
        });
      });
  };
}

export function getFormulaRestrictionAnalyze(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/formulator/formulas/${productId}/analyze`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_RESTRICTION_ANALYZE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaRestrictionAnalyze(productId),
        });
      });
  };
}

export function getSharedRestrictionAnalyze(partnershipId, productId) {
  return (dispatch) => {
    return axiosInstance
      .get(
        `/rest_api/companies/company/partnerships/${partnershipId}/shared-items/${productId}/restriction-analyze`,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_RESTRICTION_ANALYZE,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getSharedRestrictionAnalyze(
            partnershipId,
            productId,
          ),
        });
      });
  };
}

export function createRawMaterial(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${productId}/compound-ingredient-params`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createRawMaterial(productId, data),
        });
      });
  };
}

export function updateRawMaterialToFormula(productId, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productId}/compound-params`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: ADD_FORMULA_INGREDIENT_COMPOUND,
          data: response.data,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateRawMaterialToFormula(productId, data),
        });
      });
  };
}

export function updateCompoundIngredientParamList(data) {
  let paramType = 'inci';
  let paramResult = UPDATE_COMPOUND_INGREDIENT_PARAM_LIST;

  switch (data.ingType) {
    case INGREDIENT_TYPE.IMPURITIES:
      paramType = 'impurity';
      paramResult = UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST;
      break;
    case INGREDIENT_TYPE.ALLERGENS:
      paramType = 'allergen';
      paramResult = UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST;
      break;
    case INGREDIENT_TYPE.INCIDENTAL:
      paramType = 'incidental';
      paramResult = UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST;
      break;
    default:
      break;
  }

  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/compound_ingredient/${data.compoundId}/param/sync?param_type=${paramType}`,
        {
          raw_ingredients: data.raw_ingredients,
          exclude_duplicates: data.exclude_duplicates,
        },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: paramResult,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateCompoundIngredientParamList(data),
        });
      });
  };
}

export function uploadFileCompound(data) {
  const reqUrl = data.paramId
    ? `/rest_api/formulator/compound_ingredient/${data.ingredientId}/param/${data.paramId}/files`
    : `/rest_api/formulator/compound_ingredient/${data.ingredientId}/files`;

  return (dispatch) => {
    return axiosInstance
      .post(reqUrl, data.data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: uploadFileCompound(data),
        });
      });
  };
}

export function uploadFileProduct(data) {
  const reqUrl = `/rest_api/formulator/products/${data.productId}/files`;

  return (dispatch) => {
    return axiosInstance
      .post(reqUrl, data.data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: uploadFileProduct(data),
        });
      });
  };
}

export function uploadFileFormula(data) {
  const reqUrl = `/rest_api/formulator/formulas/${data.productId}/files`;

  return (dispatch) => {
    return axiosInstance
      .post(reqUrl, data.data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FORMULA_FILE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: uploadFileFormula(data),
        });
      });
  };
}

export function updateFileCompound(data) {
  const reqUrl = data.paramId
    ? `/rest_api/formulator/compound_ingredient/${data.ingredientId}/param/${data.paramId}/files/${data.fileId}`
    : `/rest_api/formulator/compound_ingredient/${data.ingredientId}/files/${data.fileId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data.data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFileCompound(data),
        });
      });
  };
}

export function updateFileProduct(data) {
  const getIngredientType = data.isCompound
    ? 'compound-ingredient-params'
    : 'ingredients';

  const reqUrl = data.paramId
    ? `/rest_api/formulator/products/${data.productId}/${getIngredientType}/${data.paramId}/files/${data.fileId}`
    : `/rest_api/formulator/products/${data.productId}/files/${data.fileId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data.data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFileProduct(data),
        });
      });
  };
}

export function updateFileFormula(data) {
  const getIngredientType = data.isCompound
    ? 'compound-params'
    : 'params';

  const reqUrl = data.paramId
    ? `/rest_api/formulator/formulas/${data.productId}/${getIngredientType}/${data.paramId}/files/${data.fileId}`
    : `/rest_api/formulator/formulas/${data.productId}/files/${data.fileId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data.data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        const resultData = {
          ...response,
          type: data.currentFileType
            ? data.currentFileType
            : response.type,
          ...(
            data.data?.update_param_name === 'compound_id' ||
            data.data?.update_param_name === 'param_id' ||
            data.currentParamId
              ? {
                param_id: data.data.update_param_value
                  ? Number(data.data.update_param_value)
                  : data.currentParamId,
              }
              : {}
          ),
        }

        dispatch({
          type: UPDATE_FORMULA_FILE,
          data: resultData,
        });

        return resultData
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFileFormula(data),
        });
      });
  };
}

export function uploadFile(file) {
  return (dispatch) => {
    return axiosInstance
      .patch(`/rest_api/companies/company`, file, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: uploadFile(file),
        });
      });
  };
}

export function getFormulaFiles(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/files`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_FORMULA_FILES,
          data: response.data,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaFiles(formulaId),
        });
      });
  };
}

export function deleteRMFile(data) {
  const reqUrl = data.paramId
    ? `/rest_api/formulator/compound_ingredient/${data.ingredientId}/param/${data.paramId}/files/${data.fileId}`
    : `/rest_api/formulator/compound_ingredient/${data.ingredientId}/files/${data.fileId}`;

  return (dispatch) => {
    return axiosInstance
      .delete(reqUrl, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: DELETE_RM_FILE_SUCCESS,
          data: response,
        });

        return {type: DELETE_RM_FILE_SUCCESS, headers: response.headers};
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteRMFile(data),
        });
      });
  };
}

export function deleteProductFile(data) {
  const getIngredientType = data.isCompound
    ? 'compound-ingredient-params'
    : 'ingredients';
  const reqUrl = data.paramId
    ? `/rest_api/formulator/products/${data.ingredientId}/${getIngredientType}/${data.paramId}/files/${data.fileId}`
    : `/rest_api/formulator/products/${data.ingredientId}/files/${data.fileId}`;

  return (dispatch) => {
    return axiosInstance
      .delete(reqUrl, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteProductFile(data),
        });
      });
  };
}

export function deleteFormulaFile({ formulaId, fileId, paramId, isCompound }) {
  const url = paramId
    ? `/rest_api/formulator/formulas/${formulaId}/${isCompound ? 'compound-params' : 'params'}/${paramId}/files/${fileId}`
    : `/rest_api/formulator/formulas/${formulaId}/files/${fileId}`;

  return (dispatch) => {
    return axiosInstance
      .delete(url, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: DELETE_FORMULA_FILE,
          data: fileId,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: deleteFormulaFile({ formulaId, fileId, paramId, isCompound }),
        });
      });
  };
}

export function assignCompanyBrand(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/companies/company/assign_brand`,
        { email_text: data },
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILE_COMPOUND,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: assignCompanyBrand(data),
        });
      });
  };
}

export function getProductNotes(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/products/${productId}/notes`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_NOTES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getProductNotes(productId),
        });
      });
  };
}
export function getScreeningNotifications(formulaId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${formulaId}/screening_notifications`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_SCREENING_NOTIFICATIONS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getScreeningNotifications(formulaId),
        });
      });
  };
}

export function getFormulaNotes(productId) {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/formulator/formulas/${productId}/notes`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_NOTES,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getFormulaNotes(productId),
        });
      });
  };
}

export function createProductNote(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${data.productId}/notes`,
        data.data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCT_NOTE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createProductNote(data),
        });
      });
  };
}

export function createFormulaNote(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${data.productId}/notes`,
        data.data,
        getConfig(),
      )
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: CREATE_PRODUCT_NOTE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: createFormulaNote(data),
        });
      });
  };
}

export function markAllNotificationsRead() {
  return (dispatch) => {
    return axiosInstance
      .post(`/rest_api/notifications`, {}, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: MARK_ALL_AS_READ_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: markAllNotificationsRead(),
        });
      });
  };
}

export function updateProductNote(data, productId, noteId) {
  const reqUrl = `/rest_api/formulator/products/${productId}/notes/${noteId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_NOTE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateProductNote(data, productId, noteId),
        });
      });
  };
}

export function updateFormulaNote(data, productId, noteId) {
  const reqUrl = `/rest_api/formulator/formulas/${productId}/notes/${noteId}`;

  return (dispatch) => {
    return axiosInstance
      .put(reqUrl, data, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_NOTE,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: updateFormulaNote(data, productId, noteId),
        });
      });
  };
}


export function removeProductNote(noteId, prodId) {
  const reqUrl = `/rest_api/formulator/products/${prodId}/notes/${noteId}`;

  return (dispatch) => {
    return axiosInstance
      .delete(reqUrl, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeProductNote(noteId, prodId),
        });
      });
  };
}

export function removeFormulaNote(noteId, prodId) {
  const reqUrl = `/rest_api/formulator/formulas/${prodId}/notes/${noteId}`;

  return (dispatch) => {
    return axiosInstance
      .delete(reqUrl, getConfig())
      .then((response) => {
        return { ...response.data, headers: response.headers };
      })
      .then((response) => {
        dispatch({
          type: CREATE_COMPOUND_INGREDIENT,
          data: response,
        });

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: removeFormulaNote(noteId, prodId),
        });
      });
  };
}

export function requestCreateDocumentArchive(productId, isOwner = true) {
  const requestType = isOwner ? 'create_archive_owner' : 'create_archive_shared_user';

  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/products/${productId}/${requestType}`,
        null,
        getConfig()
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestCreateDocumentArchive(productId, isOwner = true),
        });

        return Promise.reject(error);
      })
  }
}

export function requestCreateFormulaDocumentArchive(productId, isOwner = true) {
  const requestType = isOwner ? 'archive' : 'shared-archive';

  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/formulas/${productId}/${requestType}`,
        null,
        getConfig()
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestCreateFormulaDocumentArchive(productId, isOwner = true),
        });

        return Promise.reject(error);
      })
  }
}

export function logRegulatoryLinkOpen(data) {
  return (dispatch) => {
    return axiosInstance
      .post('/rest_api/toxins/regulatory-link', data, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: LOG_REGULATORY_LINK_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: logRegulatoryLinkOpen(data),
        });
      });
  };
}

export function requestDocuments(id, data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/compound_ingredient/${id}/request_rm_docs`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: REQUEST_DOCUMENTS_SUCCESS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: requestDocuments(id, data),
        });
      });
  };
}

export function syncCentricRM(data) {
  const compoundIngredients = data.compoundIngredientIds?.length
      ? `?compound_ingredient_ids=${data.compoundIngredientIds.join('&compound_ingredient_ids=')}`
      : '';

  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/formulator/compound_ingredient/centric-sync${compoundIngredients}`,
            {},
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_DOCUMENTS_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: syncCentricRM(data),
          });

          return error
        });
  };
}

export function syncCentricFormulas(data) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/formulator/formulas/${data.formula_id}/centric-sync`,
            { centric_sync_sku_codes: data.centric_sku },
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: REQUEST_SYNC_CENTRIC_SUCCESS,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: syncCentricFormulas(data),
          });

          return error
        });
  };
}

export function formulatorFormulasResort(data) {
  return (dispatch) => {
    return axiosInstance
        .post(
            `/rest_api/formulator/formulas/${data.formula_id}/resort`,
            data.request,
            getConfig(),
        )
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: FORMULATOR_FORMULA_RESORT,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: formulatorFormulasResort(data),
          });

          return error
        });
  };
}

export function formulatorFormulasTrackRMReplaced(data) {
  return (dispatch) => {
    return axiosInstance
        .post(
            '/rest_api/formulator/formulas/track-rm-replaced',
          { rm_ids: data },
            getConfig(),
        )
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: formulatorFormulasTrackRMReplaced(data),
          });
        });
  };
}

export function lockCompoundIngredient(data) {
  return (dispatch) => {
    return axiosInstance
        .put(`/rest_api/formulator/compound_ingredient/${data.lock ? 'lock' : 'unlock'}`, {compound_ingredient_ids: data.ingredients}, getConfig())
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch({
            type: LOCK_COMPOUND_INGREDIENT,
            data: response,
          });

          return response;
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: lockCompoundIngredient(data),
          });
        });
  };
}

export function closeError() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ERROR,
    });
  };
}

export function cleareProduct() {
  return (dispatch) => {
    dispatch({
      type: CLEARE_PRODUCT,
    });
  };
}

export function clearFormulatorProduct() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FORMULATOR_PRODUCT,
    });
  };
}

export function clearFormulatorFormula() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FORMULATOR_FORMULA,
    });
  };
}

export function clearRestrictions() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RESTRICTIONS,
    });
  };
}

export function clearParams() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PARAMS,
    });
  };
}

export function clearProductSizes() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PRODUCT_SIZES,
    });
  };
}

export function clearConsumerProduct() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PRODUCT_DBS,
    });
  };
}

export function updateStateOfNfIngredients() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FORMULATOR_FORMULA_NF_PARAMS,
    });
  };
}

export function updateChangedRMInFormula(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: UPDATE_CHANGED_RM_IN_FORMULA,
    });
  };
}

export function updateCompoundIngredientParamImpurityField(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD,
    });
  };
}

export function updateCompoundIngredientParamAllergenField(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD,
    });
  };
}

export function updateCompoundIngredientParamField(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD,
    });
  };
}

export function updateCompoundIngredientIncidentalField(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD,
    });
  };
}

export function setUploadedDocs(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: SET_UPLOADED_DOCS,
    });
  };
}

export function setEditedUser(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: GET_EDITED_USER,
    });
  };
}

export function setEditedUserPermissions(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: GET_EDITED_USER_PERMISSIONS,
    });
  };
}

export function setShowMaintenanceMessage(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: SET_SHOW_MAINTENANCE_MESSAGE,
    });
  };
}

export function setGetAllPermissionsStarted(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: GET_ALL_PERMISSIONS_STARTED,
    });
  };
}

export function setPreviousLocation(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: SET_PREVIOUS_LOCATION,
    });
  };
}

export function resortFormulaParams(data) {
  return (dispatch) => {
    dispatch({
      data: data,
      type: RESORT_FORMULA_PARAMS,
    });
  };
}


export function segmentFrequency(data) {
  return (dispatch) => {
    return axiosInstance
      .post(
        `/rest_api/formulator/compound_ingredient/segment/track-rm-frequency-click`,
        data,
        getConfig(),
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: segmentFrequency(data),
        });
      });
  };
}
